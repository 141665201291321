import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Location from "../components/location"

export default class ContactPage extends Component {
  state = {
    name: "",
    _replyto: "",
    date: "",
    phone: "",
    message: "",
    venue: "",
    errors: {},
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    //return this if errors has length event.preventDefault()
    let errors = {}

    if (this.state.name === "" || !this.state.name.length) {
      errors.name = "Name is required"
    }

    if (this.state._replyto === "" || !this.state._replyto.length) {
      errors._replyto = "Email Address is required"
    }

    if (this.state.date === "" || !this.state.date.length) {
      errors.date = "Event Date is required"
    }

    if (this.state.venue === "" || !this.state.venue.length) {
      errors.venue = "Event Venue is required"
    }

    if (this.state.phone === "" || !this.state.phone.length) {
      errors.phone = "Phone Number is required"
    }

    if (this.state.message === "" || !this.state.message.length) {
      errors.message = "Message is required"
    }

    if (Object.keys(errors).length) {
      event.preventDefault()
      this.setState({ errors: errors })
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Contact Willow Branch Flowers" />
        <section className="bg-textured-content py-12 border-b border-gray-400">
          <div className="md:flex container mx-auto">
            <div className="md:w-1/3 pr-12 px-2 md:px-0">
              <h1 className="text-3xl font-serif font-bold text-green-800 mb-2">
                Contact
              </h1>

              <Location subPage />
            </div>
            <div className="md:w-2/3 md:pl-4 leading-loose">
              {/* this iframe is what we were using, but I hid it when I added the message about shutting down */}
              {/* <iframe
                src="https://app.curate.co/form/willow-branch-flowers-and-design/c3c7f681-05b1-496b-8130-0ffe60b7ae97"
                width="700"
                height="1500"
                class="my-studio-form"
                style={{ width: "100%" }}
                frameborder="0"
              ></iframe> */}
              {/* <form method="post" action="https://formspree.io/xqkeygav" onSubmit={this.handleSubmit} className="px-2 md:px-0">
              <input type="text" name="_gotcha" style={{display:'none'}} />
              {Object.keys(this.state.errors).length ? (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-12" role="alert">
                  <strong className="font-bold">Please correct the following errors: </strong>
                  <ul className="list-reset">
                    {Object.keys(this.state.errors).map((err, idx)=>{
                      return (
                        <li key={idx}>{this.state.errors[err]}</li>
                      )
                    })}
                  </ul>
                </div>
              ) : null}
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input name="name" onChange={this.handleInputChange} className="shadow appearance-none border rounded w-full md:w-3/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Your Full Name" />
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="_replyto">
                  Email Address
                </label>
                <input name="_replyto" onChange={this.handleInputChange} className="shadow appearance-none border rounded w-full md:w-3/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="_replyto" type="text" placeholder="Email Address" />
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
                  Event Date
                </label>
                <input name="date" onChange={this.handleInputChange} className="shadow appearance-none border rounded w-full md:w-3/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="text" placeholder="Date of Event" />
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="venue">
                  Event Venue
                </label>
                <input name="venue" onChange={this.handleInputChange} className="shadow appearance-none border rounded w-full md:w-3/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="venue" type="text" placeholder="Event Venue/Location" />
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                  Phone Number
                </label>
                <input name="phone" onChange={this.handleInputChange} className="shadow appearance-none border rounded w-full md:w-3/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="tel" placeholder="Phone Number" />
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                  Your Message
                </label>
                <textarea name="message" onChange={this.handleInputChange} rows="8" className="shadow appearance-none border rounded w-full md:w-3/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="message" type="tel" placeholder="How can we help you?"></textarea>
              </div>

              <div className="mb-4">
                <button type="submit" className="inline-block py-2 px-4 w-1/3 rounded text-white font-bold hover:bg-green-600 bg-green-800">Get In Touch</button>
              </div>
          </form> */}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
